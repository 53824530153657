import { Module } from 'vuex';
import { RootState } from '@/store/models';
import { AddMessage, Message, MessengerState } from '@/store/messenger/models';

const ADD = 'ADD';
const DEL = 'DEL';

const moduleMessage: Module<MessengerState, RootState> = {
  namespaced: true,
  state: (): MessengerState => ({
    messages: {},
  }),
  mutations: {
    [ADD]: (state: MessengerState, payload: Message) => {
      state.messages[payload.id] = payload;
    },
    [DEL]: (state: MessengerState, id: string) => {
      delete state.messages[id];
    },
  },
  actions: {
    add: ({ commit }, payload: AddMessage): void => {
      const id = new Date().getTime();
      commit(ADD, { ...payload, id });
      setTimeout(() => {
        commit(DEL, id);
      }, 5000);
    },
    del: ({ commit }, id: string) => {
      commit(DEL, id);
    },
  },
  getters: {
    messages: (state: MessengerState): Message[] => Object.values(state.messages),
  },
};

export default moduleMessage;
