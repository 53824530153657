
import { defineComponent } from 'vue';
import SideLink from '@/components/layout/aside/SideLink.vue';
import { mapGetters } from 'vuex';

export interface NestedRoute {
  routeName: string;
  text: string;
}

export default defineComponent({
  name: 'SideNavigation',

  components: { SideLink },

  computed: mapGetters('auth', ['permissions']),
});
