import { Module } from 'vuex';
import { RootState } from '@/store/models';
import { reasonMessage } from '@/utils/reason-message';
import { setAuthorization } from '@/client';
import { getAdminCurrent, cancelGetAdminCurrent } from '@/client/api/admin';
import { putAdminAuth } from '@/client/api/auth';
import { AuthState } from '@/store/auth/models';
import { AxiosError } from 'axios';
import AdminAuthInfo = Components.Admin.Auth.Info;
import Permission = Components.Models.AdminPermission;

const USER = 'USER';
const TOKEN = 'TOKEN';
const INFO = 'INFO';

const moduleAuth: Module<AuthState, RootState> = {
  namespaced: true,
  state: (): AuthState => ({
    authInfo: null,
    userDetails: null,
  }),
  mutations: {
    [USER]: (state: AuthState, details?: Components.Admin.Admin.Details) => {
      state.userDetails = details || null;
    },
    [INFO]: (state: AuthState, info?: Components.Admin.Auth.Info) => {
      state.authInfo = info || null;
    },
  },
  actions: {
    async login({ commit, dispatch }, params: Paths.AdminAuth.Put.RequestBody): Promise<void> {
      return putAdminAuth(params)
        .then((info: AdminAuthInfo) => {
          commit(INFO, info);
          setAuthorization(info);
          dispatch('load');
        });
    },
    logout({ commit }): void {
      cancelGetAdminCurrent('logout');
      commit(USER, null);
      commit(INFO, null);
      setAuthorization(undefined);
    },
    async load({ commit, dispatch }) {
      return getAdminCurrent().then((user: Components.Admin.Admin.Details) => {
        commit(USER, user);
      }).catch(async (err: AxiosError) => {
        await dispatch('message/add', reasonMessage(err), { root: true });
        commit(USER, null);
        const { status } = err.response || {};
        if (status === 401 || status === 403) await dispatch('logout');
      });
    },
    token({ commit }, token?: string) {
      commit(TOKEN, token);
    },
  },
  getters: {
    isBlocked: (state: AuthState): boolean => !!state.userDetails?.blocked,
    isLoggedIn: (state: AuthState): boolean => !!state.userDetails,
    login: (state: AuthState): string | null | unknown => state.userDetails?.login,
    permissions: (state: AuthState): Permission[] => state.userDetails?.permissions || [],
  },
};

export default moduleAuth;
