import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "base-layout__main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_header = _resolveComponent("base-header")!
  const _component_base_aside = _resolveComponent("base-aside")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_base_header),
    _createVNode(_component_base_aside),
    _createElementVNode("main", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 64))
}