
import { defineComponent } from 'vue';
import ButtonComponent from '@/components/buttons/Button.vue';
import { mapActions } from 'vuex';
import IconSvg from '@/components/icon-svg.vue';
import { AxiosError } from 'axios';

export default defineComponent({
  name: 'LoginForm',
  emits: ['recover'],
  components: {
    IconSvg,
    ButtonComponent,
  },
  data() {
    return {
      login: '',
      password: '',
      loading: false,
      error: '',
      showPassword: false,
    };
  },
  mounted() {
    window.addEventListener('keypress', this.onKeyPress);
  },
  beforeUnmount() {
    window.removeEventListener('keypress', this.onKeyPress);
  },
  methods: {
    ...mapActions('auth', {
      loginUser: 'login',
    }),
    async onKeyPress(evt: KeyboardEvent): Promise<void> {
      if (evt.key === 'Enter') await this.submit();
    },
    async submit() {
      this.loading = true;
      return this.loginUser({
        login: this.login,
        password: this.password,
      }).catch((reason: AxiosError<string>) => {
        this.error = reason.response?.data || 'Логин и/или пароль введён неверно';
      }).finally(() => {
        this.loading = false;
      });
    },
    dropError(): void {
      if (this.error) this.error = '';
    },
    togglePassword(): void {
      this.showPassword = !this.showPassword;
    },
  },
});
