import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_auth_loader = _resolveComponent("auth-loader")!
  const _component_auth_view = _resolveComponent("auth-view")!
  const _component_auth_layout = _resolveComponent("auth-layout")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_base_layout = _resolveComponent("base-layout")!
  const _component_messages = _resolveComponent("messages")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading || !_ctx.isLoggedIn || _ctx.isBlocked)
      ? (_openBlock(), _createBlock(_component_auth_layout, { key: 0 }, {
          default: _withCtx(() => [
            (_ctx.loading)
              ? (_openBlock(), _createBlock(_component_auth_loader, { key: 0 }))
              : (_openBlock(), _createBlock(_component_auth_view, { key: 1 }))
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_base_layout, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_router_view)
          ]),
          _: 1
        })),
    _createVNode(_component_messages)
  ], 64))
}