// @ts-ignore
import createApiClient from '@/client/create-api-client';
import { AxiosError, AxiosInstance } from 'axios';
import cookies from '@/utils/cookie';
import { Store } from 'vuex';
import { RootState } from '@/store/models';

const AUTH_TOKEN_NAME = 'lkp-admin-token';

const client: AxiosInstance = createApiClient();

function setInterceptors(store: Store<RootState>): void {
  client.interceptors.response.use(undefined, async (error: AxiosError) => {
    if (
      error.code === '401'
      || error.response?.status === 401
      || error.code === '418'
      || error.response?.status === 418
    ) await store.dispatch('user/logout');
    return Promise.reject(error);
  });
}

function setAuthorizationHeader(token?: string): void {
  if (token) client.defaults.headers.common.Authorization = `Bearer ${token}`;
  else delete client.defaults.headers.common.Authorization;
}

function getAuthorization(): boolean {
  const token = cookies.get(AUTH_TOKEN_NAME);
  setAuthorizationHeader(token);
  return !!token;
}

function setAuthorization(payload?: Components.Admin.Auth.Info): void {
  if (payload && payload.token) {
    cookies.set(AUTH_TOKEN_NAME, payload.token, { expires: payload.lifeTime });
    setAuthorizationHeader(payload.token);
  } else {
    cookies.del(AUTH_TOKEN_NAME);
    setAuthorizationHeader();
  }
}

export { AxiosResponse, AxiosError } from 'axios';

export {
  client,
  setAuthorization,
  getAuthorization,
  setInterceptors,
  AUTH_TOKEN_NAME,
};
