
import { defineComponent } from 'vue';
import IconSvg from '@/components/icon-svg.vue';

const titles: {[key: number]: string } = {
  400: '400. Bad Request',
  401: '401. Unauthorized',
  403: '403. Forbidden',
  404: '404. Страница не найдена',
  408: '408. Request Timeout',
  409: '409. Conflict',
  500: '500. Ошибка в настройках сайта',
  502: '502 Bad Gateway',
  503: '503. Service Temporarily Unavailable',
};

const texts: {[key: number]: string } = {
  400: 'Неверный запрос! Сервер не может распознать ваш запрос. Проверьте адрес в строке браузера, cookies или кэш.',
  401: 'Вы не авторизованы. Для доступа к этой странице необходимо авторизоваться на сайте',
  403: 'В доступе отказано. Вы запросили страницу, доступ к кторой ограничен. Это всё, что мы знаем',
  404: 'Вероятно, нужная вам страница была удалена или находится по другому адресу',
  408: 'Время ожидания запроса истекло. Веб-серверу потребовалось больше времени для обработки запроса',
  409: 'Конфликт запроса с текущим состоянием сервера. Данные уже существуют',
  500: 'Вероятнее всего, эта ошибка возникла из-за несовместимых настроек сайта',
  502: 'Ошибка сервера.',
  503: 'Сервер временно недоступен. Сейчас он не может обработать вваш запрос.',
};

export default defineComponent({
  name: 'HTTPMessage',
  emits: ['close'],
  components: {
    IconSvg,
  },
  props: {
    status: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      title: '',
      text: '',
    };
  },
  created() {
    this.title = titles[this.status] || '';
    this.text = texts[this.status] || '';
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
});
