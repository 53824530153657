import { RouteRecordRaw } from 'vue-router';

const usersRoutes: Array<RouteRecordRaw> = [
  {
    meta: { permissions: 'AdminManagement' },
    path: '/users/:id',
    alias: '/users/:id/',
    name: 'user-view',
    component: () => import(/* webpackChunkName: "users" */ '../views/users/user-view/UserView.vue'),
  },
  {
    meta: { permissions: 'AdminManagement' },
    path: '/users/providers/:id',
    alias: '/users/providers/:id/',
    name: 'user-provider-view',
    component: () => import(/* webpackChunkName: "provider" */ '../views/users/provider-view/LkpProviderView.vue'),
  },
  {
    meta: { title: 'Управление пользователями ЛКП', permissions: 'AdminManagement' },
    path: '/users',
    alias: '/users/',
    name: 'users-list',
    component: () => import(/* webpackChunkName: "users" */ '../views/users/UsersListView.vue'),
  },
];

export default usersRoutes;
