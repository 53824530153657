import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "auth-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_svg = _resolveComponent("icon-svg")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createVNode(_component_router_link, {
      class: "auth-header__link",
      to: {name: 'home'}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_icon_svg, {
          class: "auth-header__logo",
          symbol: "logo"
        })
      ]),
      _: 1
    })
  ]))
}