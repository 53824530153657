
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import ButtonComponent from '@/components/buttons/Button.vue';

export default defineComponent({
  name: 'UserBlocked',
  emits: ['back'],
  components: {
    ButtonComponent,
  },
  computed: mapGetters('auth', ['login']),
  created() {
    if (!this.login) this.$emit('back');
  },
});
