
import { defineComponent, PropType } from 'vue';
import IconSvg from '@/components/icon-svg.vue';
import { mapState } from 'vuex';
import { NestedRoute } from '@/components/layout/aside/SideNavigation.vue';

export default defineComponent({
  name: 'SideLink',
  components: { IconSvg },
  props: {
    routeName: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    nested: {
      type: Array as PropType<NestedRoute[]>,
      required: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapState('sidebar', ['visible']),
    isActive(): boolean {
      if (!this.nested || !this.nested.length) return false;
      let isActive = false;
      this.nested.forEach((link: NestedRoute) => {
        if (link.routeName === this.$route.name) isActive = true;
      });
      return isActive;
    },
  },
  watch: {
    visible(status: boolean): void {
      if (!status) this.close();
    },
  },
  methods: {
    toggle(): void {
      this.isOpen = !this.isOpen;
    },
    close(): void {
      this.isOpen = false;
    },
  },
});
