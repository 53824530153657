<template>
  <div class="auth-loader">
    <div class="auth-loader__dot"></div>
    <div class="auth-loader__dot"></div>
    <div class="auth-loader__dot"></div>
  </div>
</template>

<style lang="less">
.auth-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 110px;
  height: 48px;
  transform: translate(-50%, -50%);

  &__dot {
    width: 20px;
    height: 20px;
    background-color: #e9edfa;
    border-radius: 50%;

    &:first-child {
      animation: dot1 4s ease-in-out infinite alternate;
    }

    &:last-child {
      animation: dot3 4s ease-in-out infinite alternate;
    }

    &:nth-child(2) {
      background-color: #90A3E7;
      animation: dot2 4s ease-in-out infinite alternate;
    }
  }
}

@keyframes dot1 {
  22%,
  33%,
  67%,
  77% {
    transform: translateX(45px);
    background-color: #90A3E7;
  }
  45%,
  55% {
    background-color: #e9edfa;
    transform: translateX(90px);
  }
  10%,
  90%,
  100% {
    background-color: #e9edfa;
    transform: translateX(0px);
  }
}

@keyframes dot2 {
  22%,
  77% {
    background-color: #e9edfa;
    transform: translateX(-45px);
  }
  10%,
  90%,
  100% {
    transform: translateX(0px);
    background-color: #90A3E7;
  }
}

@keyframes dot3 {
  45%,
  55% {
    transform: translateX(-45px);
    background-color: #90A3E7;
  }
  33%,
  67%,
  100% {
    background-color: #e9edfa;
    transform: translateX(0px);
  }
}
</style>
