import { RouteRecordRaw } from 'vue-router';

const reportsRoutes: Array<RouteRecordRaw> = [
  {
    meta: {
      title: 'Управление отчётами',
      permissions: 'ReportManagement',
    },
    path: '/reports',
    alias: '/reports/',
    name: 'reports-list',
    component: () => import(
      /* webpackChunkName: "reports" */
      '../views/reports/reports-list-view/ReportsListView.vue'
    ),
  },
  {
    meta: { permissions: 'ReportManagement' },
    path: '/reports/:type?/:id',
    alias: '/reports/:type?/:id/',
    name: 'report-view',
    component: () => import(
      /* webpackChunkName: "reports" */
      '../views/reports/report-view/ReportView.vue'
    ),
  },
];

export default reportsRoutes;
