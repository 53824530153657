import { RouteRecordRaw } from 'vue-router';

const adminsRoutes: Array<RouteRecordRaw> = [
  {
    meta: { permissions: 'AdminManagement' },
    path: '/admins/:id',
    alias: '/admins/:id/',
    name: 'admin-view',
    component: () => import(/* webpackChunkName: "admins" */ '../views/admins/admin-view/AdminView.vue'),
  },
  {
    meta: { title: 'Управление админами', permissions: 'AdminManagement' },
    path: '/admins',
    alias: '/admins/',
    name: 'admins-list',
    component: () => import(/* webpackChunkName: "admins" */ '../views/admins/admins-list-view/AdminsListView.vue'),
  },
];

export default adminsRoutes;
