
export default {
  name: 'IconSvg',
  props: {
    symbol: {
      type: String,
      required: true,
    },
  },
};
