import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "confirmation-modal__content" }
const _hoisted_2 = {
  key: 0,
  class: "modal__icon"
}
const _hoisted_3 = {
  key: 1,
  class: "modal__head"
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "confirmation-modal__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_svg = _resolveComponent("icon-svg")!
  const _component_button_component = _resolveComponent("button-component")!
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createBlock(_component_modal, { onClose: _ctx.close }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.icon)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_icon_svg, { symbol: _ctx.icon }, null, 8, ["symbol"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.title)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.description)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: "modal__description",
              innerHTML: _ctx.description
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default")
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_button_component, {
          class: "text-button",
          text: _ctx.confirmText,
          onClick: _withModifiers(_ctx.confirm, ["stop"])
        }, null, 8, ["text", "onClick"]),
        (_ctx.hasReject)
          ? (_openBlock(), _createBlock(_component_button_component, {
              key: 0,
              class: "primary-button",
              text: _ctx.rejectText,
              onClick: _withModifiers(_ctx.reject, ["stop"])
            }, null, 8, ["text", "onClick"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }, 8, ["onClose"]))
}