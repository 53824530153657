
import { defineComponent } from 'vue';
import BaseHeader from '@/components/layout/base/BaseHeader.vue';
import BaseAside from '@/components/layout/aside/Aside.vue';

export default defineComponent({
  name: 'BaseLayout',
  components: {
    BaseHeader,
    BaseAside,
  },
});
