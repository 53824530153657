
import { defineComponent } from 'vue';
import IconSvg from '@/components/icon-svg.vue';
import Modal from '@/components/modal/ModalConfirmation.vue';
import { mapActions, mapState } from 'vuex';

export default defineComponent({
  name: 'BaseHeader',
  components: { Modal, IconSvg },
  data() {
    return {
      logoutModal: false,
    };
  },
  computed: mapState('auth', ['userDetails']),
  methods: {
    ...mapActions('auth', ['logout']),
    async confirmLogout(): Promise<void> {
      if (this.$route.name !== 'home') this.$router.push({ name: 'home' });
      this.logout();
    },
  },
});
