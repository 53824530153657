import { createApp } from 'vue';
import formatUuidDirective from '@/utils/formatUuid.directive';
import IconSvg from '@/components/icon-svg.vue';
import App from './App.vue';
import router from './router';
import store from './store';

createApp(App)
  .directive('formatUuid', formatUuidDirective)
  .component('IconSvg', IconSvg)
  .use(store)
  .use(router)
  .mount('#app');
