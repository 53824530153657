
import { defineComponent } from 'vue';
import Modal from '@/components/modal/Modal.vue';
import IconSvg from '@/components/icon-svg.vue';
import ButtonComponent from '@/components/buttons/Button.vue';

export default defineComponent({
  name: 'ModalConfirmation',
  emits: ['confirm', 'reject', 'close'],
  components: {
    Modal,
    IconSvg,
    ButtonComponent,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    icon: {
      type: String,
      default: () => '',
    },
    hasReject: {
      type: Boolean,
      default: true,
    },
    confirmText: {
      type: String,
      default: 'Понятно',
    },
    rejectText: {
      type: String,
      default: 'Отмена',
    },
  },
  methods: {
    confirm(): void {
      this.$emit('confirm');
    },
    reject(): void {
      this.$emit('reject');
    },
    close(): void {
      this.$emit('close');
    },
  },
});
