import { client } from '@/client';
import { AxiosResponse } from 'axios';

async function postAuth(
  data: Paths.Auth.Post.RequestBody,
): Promise<Paths.Auth.Post.Responses.$200> {
  return client.post('/Auth', data).then((response: AxiosResponse) => response.data);
}

async function postAuthRecovery(
  data: Paths.AuthRecovery.Post.RequestBody,
): Promise<void> {
  return client.post('/Auth/Recovery', data).then((response: AxiosResponse) => response.data);
}

async function postAuthResetPassword(
  data: Paths.AuthResetPassword.Post.RequestBody,
): Promise<void> {
  return client.post('/Auth/Recovery', data).then((response: AxiosResponse) => response.data);
}

async function postAuthUpdatePassword(
  data: Paths.AuthUpdatePassword.Post.RequestBody,
): Promise<void> {
  return client.post('/Auth/UpdatePassword', data).then((response: AxiosResponse) => response.data);
}

async function putAdminAuth(
  data: Paths.AdminAuth.Put.RequestBody,
): Promise<Paths.AdminAuth.Put.Responses.$200> {
  return client.put('/Admin/Auth', data)
    .then((response: AxiosResponse) => response.data);
}

export {
  postAuth,
  putAdminAuth,
  postAuthRecovery,
  postAuthUpdatePassword,
  postAuthResetPassword,
};
