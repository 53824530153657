import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["aria-disabled"]
const _hoisted_2 = { class: "button__wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner = _resolveComponent("spinner")!
  const _component_icon_svg = _resolveComponent("icon-svg")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["button", {
      'button--loading': _ctx.loading,
      'button--error': _ctx.error,
      'button--icon-right': _ctx.symbol && _ctx.iconRight,
      'button--icon-left': _ctx.symbol && !_ctx.iconRight
    }]),
    "aria-disabled": (_ctx.disabled || _ctx.loading) ? '' : null
  }, [
    (_ctx.loading && !_ctx.symbol)
      ? (_openBlock(), _createBlock(_component_spinner, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_2, [
      (_ctx.symbol && !_ctx.iconRight)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (!_ctx.loading)
              ? (_openBlock(), _createBlock(_component_icon_svg, {
                  key: 0,
                  symbol: _ctx.symbol
                }, null, 8, ["symbol"]))
              : (_openBlock(), _createBlock(_component_spinner, { key: 1 }))
          ], 64))
        : _createCommentVNode("", true),
      (_ctx.text)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createTextVNode(_toDisplayString(_ctx.text), 1)
          ], 64))
        : _renderSlot(_ctx.$slots, "default", { key: 2 }),
      (_ctx.symbol && _ctx.iconRight)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
            (!_ctx.loading)
              ? (_openBlock(), _createBlock(_component_icon_svg, {
                  key: 0,
                  symbol: _ctx.symbol
                }, null, 8, ["symbol"]))
              : (_openBlock(), _createBlock(_component_spinner, { key: 1 }))
          ], 64))
        : _createCommentVNode("", true)
    ])
  ], 10, _hoisted_1))
}