
import { defineComponent } from 'vue';
import { getAuthorization } from '@/client';
import { mapActions, mapGetters } from 'vuex';
import BaseLayout from '@/components/layout/base/BaseLayout.vue';
import AuthView from '@/views/AuthView.vue';
import AuthLayout from '@/components/layout/auth/AuthLayout.vue';
import AuthLoader from '@/components/auth/AuthLoader.vue';
import Messages from '@/components/messages/Messages.vue';
import 'simplebar';
import 'simplebar/dist/simplebar.css';

export default defineComponent({
  name: 'App',
  components: {
    Messages,
    AuthLoader,
    AuthLayout,
    AuthView,
    BaseLayout,
  },
  async created() {
    this.loading = getAuthorization();
    if (this.loading) {
      this.load().finally(() => {
        this.loading = false;
      });
    }
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: mapGetters('auth', ['isLoggedIn', 'isBlocked']),
  methods: mapActions('auth', ['load']),
});
