import axios, { AxiosInstance } from 'axios';
import qs from 'qs';

const backendsInfo: HTMLElement | null = document.querySelector('meta[name="backend-info"]');

const baseURL = backendsInfo?.getAttribute('data-backend-url') || 'https://adminapi.supplier.katren.tech';

export default function createApiClient(): AxiosInstance {
  return axios.create({
    baseURL,
    responseType: 'json',
    paramsSerializer: (params: unknown): string => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
}
