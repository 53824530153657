
import { defineComponent } from 'vue';
import Spinner from '@/components/spinner-component.vue';
import IconSvg from '@/components/icon-svg.vue';

/**
 * https://www.figma.com/file/e0onUuRZWZvkgemlaWJiQu/%F0%9F%9F%AA-Personal-Account-Components?node-id=27%3A2764
 * CSS
 * .primary-button
 * .outline-button
 * .text-button
 */
export default defineComponent({
  name: 'ButtonComponent',
  components: {
    IconSvg,
    Spinner,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
    },
    symbol: {
      type: String,
    },
    error: {
      type: Boolean,
      default: false,
    },
    iconRight: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.saveWidth();
  },
  methods: {
    saveWidth(): void {
      const el = this.$el as HTMLButtonElement;
      const { width } = el.getBoundingClientRect();
      if (width) el.style.minWidth = `${width}px`;
    },
  },
});
