import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "aside__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_svg = _resolveComponent("icon-svg")!
  const _component_side_navigation = _resolveComponent("side-navigation")!

  return (_openBlock(), _createElementBlock("aside", {
    class: _normalizeClass(["aside", _ctx.visible ? 'aside--active' : '']),
    onClick: _cache[5] || (_cache[5] = ($event: any) => (!_ctx.isDesktop ? _ctx.open() : null))
  }, [
    _createElementVNode("div", {
      class: "aside__overlay",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.close()), ["stop"]))
    }),
    _createElementVNode("div", {
      class: "aside-wrap",
      onMouseenter: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isDesktop ? _ctx.open() : null)),
      onMousemove: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isDesktop && !_ctx.visible ? _ctx.open() : null)),
      onMouseleave: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isDesktop ? _ctx.close() : null))
    }, [
      _createElementVNode("button", {
        class: _normalizeClass(["button aside-hamburger", _ctx.visible ? 'aside-hamburger--active' : '']),
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (!_ctx.isDesktop ? _ctx.toggle() : null), ["stop"]))
      }, [
        _createVNode(_component_icon_svg, {
          symbol: "alter-burger",
          class: "aside-hamburger__icon"
        })
      ], 2),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_side_navigation)
      ])
    ], 32)
  ], 2))
}