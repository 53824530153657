import { createStore } from 'vuex';
import { RootState } from '@/store/models';
import moduleAuth from '@/store/auth';
import moduleMessage from '@/store/messenger';
import moduleSidebar from '@/store/sidebar';

export default createStore<RootState>({
  state: {
    user: null,
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth: moduleAuth,
    message: moduleMessage,
    sidebar: moduleSidebar,
  },
});
