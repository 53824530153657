
import { defineComponent } from 'vue';
import IconSvg from '@/components/icon-svg.vue';

export default defineComponent({
  name: 'Message',
  emits: ['close'],
  components: {
    IconSvg,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
    text: {
      type: [String, Array],
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
});
