
import { defineComponent } from 'vue';
import LoginForm from '@/components/auth/LoginForm.vue';
import { mapActions, mapGetters } from 'vuex';
import UserBlocked from '@/components/auth/UserBlocked.vue';

export default defineComponent({
  name: 'AuthView',
  components: {
    UserBlocked,
    LoginForm,
  },
  data() {
    return {
      recover: false,
    };
  },
  computed: mapGetters('auth', ['isBlocked']),
  methods: mapActions('auth', ['logout']),
});
