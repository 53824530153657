
import { defineComponent } from 'vue';
import IconSvg from '@/components/icon-svg.vue';

export default defineComponent({
  name: 'Modal',
  components: {
    IconSvg,
  },
  emits: ['close'],
  mounted() {
    window.addEventListener('keyup', this.onKey);
  },
  beforeUnmount() {
    window.removeEventListener('keyup', this.onKey);
  },
  methods: {
    close(): void {
      this.$emit('close');
    },
    onKey(evt: KeyboardEvent) {
      if (evt.key === 'Escape') this.close();
    },
  },
});
