import { AxiosError } from 'axios';

interface ReasonMessage {
  status: number;
  text?: string;
}

function reasonMessage(reason: AxiosError): ReasonMessage {
  const response: ReasonMessage = {
    status: 500,
  };
  if (reason.response?.status) response.status = reason.response.status;
  else if (reason.code) response.status = Number.parseInt(reason.code, 10);

  if (response.status === 400) response.text = reason.message;

  return response;
}

export {
  reasonMessage,
  ReasonMessage,
};
