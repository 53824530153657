import { Directive } from 'vue';

const formatUuidDirective: Directive = {
  beforeUpdate: (el: HTMLInputElement) => {
    const e = el;
    e.oninput = (e: Event) => {
      if (!e.isTrusted) return;
      // @ts-ignore
      const x = e.target.value.replace(/[^a-z0-9]+/g, '');
      // @ts-ignore
      e.target.value = x.replace(/(........)(....)(....)(....)(............)(.*)/, '$1-$2-$3-$4-$5');
      el.dispatchEvent(new Event('input'));
    };
  },
};

export default formatUuidDirective;
