import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HTTPMessage = _resolveComponent("HTTPMessage")!
  const _component_message = _resolveComponent("message")!

  return (_openBlock(), _createElementBlock("div", {
    class: "messages",
    id: _ctx.messages,
    style: _normalizeStyle({top: _ctx.offsetTopMessage(), maxHeight: `calc(100vh - ${_ctx.offsetTopMessage()})`})
  }, [
    _createElementVNode("div", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          (typeof message.status === 'number')
            ? (_openBlock(), _createBlock(_component_HTTPMessage, {
                key: message.id,
                status: message.status,
                onClose: ($event: any) => (_ctx.del(message.id))
              }, null, 8, ["status", "onClose"]))
            : (_openBlock(), _createBlock(_component_message, {
                key: message.id,
                text: message.text,
                title: message.title,
                status: message.status,
                onClose: ($event: any) => (_ctx.del(message.id))
              }, null, 8, ["text", "title", "status", "onClose"]))
        ], 64))
      }), 256))
    ])
  ], 12, _hoisted_1))
}