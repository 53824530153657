
import { defineComponent } from 'vue';
import SideNavigation from '@/components/layout/aside/SideNavigation.vue';
import { mapActions, mapState } from 'vuex';
import IconSvg from '@/components/icon-svg.vue';

export default defineComponent({
  name: 'BaseAside',
  components: {
    IconSvg,
    SideNavigation,
  },
  created() {
    this.checkDesktop();
    if (typeof (window) !== 'undefined') window.addEventListener('resize', this.checkDesktop);
  },
  watch: {
    $route() {
      if (this.visible) {
        setTimeout(() => {
          this.close();
        }, 350);
      }
    },
  },
  methods: {
    ...mapActions('sidebar', ['open', 'close', 'toggle']),
    checkDesktop(): void {
      this.isDesktop = window.innerWidth > 1024;
    },
  },
  computed: mapState('sidebar', ['visible']),
  data() {
    return {
      isDesktop: false,
    };
  },
});
