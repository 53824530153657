import { client } from '@/client';
import axios, { AxiosResponse, Canceler } from 'axios';

const { CancelToken } = axios;

let cancel: Canceler | undefined;

function cancelGetAdminCurrent(message: string): void {
  if (cancel) cancel(message);
  cancel = undefined;
}

// Запрос информации о текущем пользователе
async function getAdminCurrent(): Promise<Paths.AdminCurrent.Get.Responses.$200> {
  return client.get('/Admin/Current', {
    cancelToken: new CancelToken((c: Canceler) => {
      cancel = c;
    }),
  }).then((response: AxiosResponse) => response.data);
}

// Запрос списка администраторов
async function getAdminList(
  params: Paths.AdminList.Get.QueryParameters,
): Promise<Paths.AdminList.Get.Responses.$200> {
  return client.get('/Admin/List', { params })
    .then((response: AxiosResponse) => response.data);
}

// Запрос детальной информации об администраторе
async function getAdmin(
  params: Paths.Admin.Get.QueryParameters,
): Promise<Paths.Admin.Get.Responses.$200> {
  return client.get('/Admin', { params })
    .then((response: AxiosResponse) => response.data);
}

// Создание администратора
async function postAdmin(
  data: Paths.Admin.Post.RequestBody,
): Promise<Paths.Admin.Post.Responses.$200> {
  return client.post('/Admin', data)
    .then((response: AxiosResponse) => response.data);
}

// Редактирование администратора
async function putAdmin(
  params: Paths.Admin.Put.QueryParameters,
  data: Paths.Admin.Put.RequestBody,
): Promise<Paths.Admin.Put.Responses.$200> {
  return client.put('/Admin', data, { params })
    .then((response: AxiosResponse) => response.data);
}

export {
  getAdminCurrent,
  getAdminList,
  getAdmin,
  postAdmin,
  putAdmin,
  cancelGetAdminCurrent,
};
