import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "auth-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_blocked = _resolveComponent("user-blocked")!
  const _component_login_form = _resolveComponent("login-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isBlocked)
      ? (_openBlock(), _createBlock(_component_user_blocked, {
          key: 0,
          onBack: _ctx.logout
        }, null, 8, ["onBack"]))
      : (_openBlock(), _createBlock(_component_login_form, { key: 1 }))
  ]))
}