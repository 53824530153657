import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_svg = _resolveComponent("icon-svg")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "modal__wrapper",
        onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["stop"]))
      }, [
        _createElementVNode("div", {
          class: "modal",
          onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
        }, [
          _createElementVNode("button", {
            class: "modal__close",
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["stop"]))
          }, [
            _createVNode(_component_icon_svg, { symbol: "cross" })
          ]),
          _renderSlot(_ctx.$slots, "default")
        ])
      ])
    ]),
    _: 3
  }))
}