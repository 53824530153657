<template>
  <div class="home-view">
    <div class="home-view__image-container">
      <img src="/Hello_SVG.png" alt="Добро пожаловать на сайт администратора apteka.partners">
      <div class="home-view__greeting">
        Добро пожаловать на сайт администратора
        <div class="home-view__apteka-partners">
          <span class="home-view__apteka">apteka.</span>
          <span class="home-view__partners">partners</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
@import "../style/colors";
@import "../style/vars";

.home-view {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - @header-height);

  &__image-container {
    width: 100%;
    max-width: 1110px;
    margin: 20px;
    background: @white;
    border-radius: 36px;
    padding: 45px 200px 60px 200px;
    text-align: center;

    @media screen and (max-width: 1000px) {
      padding: 40px 150px 40px 150px;
    }

    @media screen and (max-width: 800px) {
      padding: 20px 100px 20px 100px;
    }

    img {
      width: 100%;
      max-width: 420px;
      height: auto;
    }
  }

  &__greeting {
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 150%;

    @media screen and (max-width: 1000px) {
      font-size: 30px;
    }

    @media screen and (max-width: 800px) {
      font-size: 26px;
    }
  }

  &__apteka-partners {
    font-style: normal;
    font-weight: 600;
    font-size: 1.6em;
    line-height: 150%;
  }

  &__apteka {
    color: @dark;
  }

  &__partners {
    color: @error;
  }
}
</style>
