import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { class: "http-message__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_svg = _resolveComponent("icon-svg")!

  return (_ctx.title)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["http-message", `http-message--${_ctx.status}`])
      }, [
        _createElementVNode("img", {
          class: "http-message__image",
          src: `/messages/${_ctx.status}.png`,
          alt: `${_ctx.status} message`
        }, null, 8, _hoisted_1),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h4", null, _toDisplayString(_ctx.title), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.text), 1)
        ]),
        _createElementVNode("button", {
          class: "http-message__close",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["stop"]))
        }, [
          _createVNode(_component_icon_svg, { symbol: "cross" })
        ])
      ], 2))
    : _createCommentVNode("", true)
}