import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "side-nav" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_side_link = _resolveComponent("side-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createVNode(_component_side_link, {
      "route-name": "users-list",
      icon: "users",
      text: "Пользователи ЛКП"
    }),
    (_ctx.permissions && _ctx.permissions.length && _ctx.permissions.includes('ReportManagement'))
      ? (_openBlock(), _createBlock(_component_side_link, {
          key: 0,
          "route-name": "reports-list",
          icon: "reports_menu",
          text: "Управление отчётами"
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_side_link, {
      "route-name": "admins-list",
      icon: "admins",
      text: "Управление админами"
    })
  ]))
}